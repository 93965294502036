@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .prose a {
    @apply underline-offset-4 lg:underline-offset-8;
  }

  .underline-link {
    @apply underline transition-all underline-offset-8 decoration-transparent hover:decoration-current;
  }

  .block-title {
    @apply text-lg leading-snug mb-9 xl:mb-24 lg:text-2xl;
  }

  .form-label {
    @apply mb-2 text-sm font-bold leading-none uppercase text-caret;
  }

  .form-input {
    @apply border rounded-[16px] px-4 py-2;
  }

  .form-help {
    @apply text-blue italic text-[12px];
  }

  .prose ul {
    @apply pl-0 list-none;
  }

  .prose ol {
    @apply pl-5 list-decimal;
  }

  .prose ul li {
    @apply relative pl-4;
  }
  .prose ul li:before {
    content: "–";
    left: 0rem;
    position: absolute;
  }

  .prose ol li {
    @apply pl-0 marker:text-blue;
  }

  @screen lg {
    .prose {
      @apply text-base leading-[30px];
    }
  }
}

@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  src: url("../fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: url("../fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  src: url("../fonts/OpenSans-Bold.ttf");
}

.responsive-object {
  position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
